import React from 'react';

const IconLoader = () => (
  <svg id="logo" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 722 375.83">

    <title>Loader Logo</title>
    <path className="cls-1"
      d="M336.25,466.72c6.61-4.14,0-7.71,2.53-13.57C356.54,420.37,442.33,341,442.33,330.08c0-5.14-2.57-9.32-6.75-9.32-18,0-115.35,92.22-115.35,113.75C320.23,458,329.46,471,336.25,466.72Z"
      transform="translate(-139 -312.09)"
      fill="currentColor"/>
    <path className="cls-1"
      d="M435.26,331c2.89-2.89-4.18-19-18-19-36,0-193.44,50.77-193.44,110.53s51.09,102.18,51.09,138.81l.27-11.81H187.92c-1.69,1.69-48.92,33.74-48.92,56.16,0,4.57,4.82,7.47,92.06,7.47,42.41,0,90.13-62.9,90.13-77.84,0-38.56-54.95-95-54.95-128.69C266.24,379.24,426.26,331,435.26,331Z"
      transform="translate(-139 -312.09)"
      fill="currentColor"/>
    <path className="cls-1"
      d="M474,379.24c24.82,0,25.79,24.1,25.79,28.92,0,17.59-39.53,66.27-101.94,66.27l1.92-14c29.4,0,80-28.68,80-43.62,0-1.93-1-6.75-11.56-6.75-24.34,0-92.3,83.87-58.57,83.87,37.36,0,109.06-55.67,109.06-55.67l2.17,19S434,535.41,386.74,535.41C287.45,535.41,418.07,379.24,474,379.24Z"
      transform="translate(-139 -312.09)"
      fill="currentColor"/>
    <path className="cls-1"
      d="M591.75,494c-31.09,0,34.22-53,34.22-83.87,0-11.81-9.4-30.85-31.09-30.85-35.18,0-131.58,108.45-131.58,129.42s15.19,26.75,24.1,26.75c12.26,0,42.44-21.65,59.6-34.78-6,15.94-8.44,34.78,13.66,34.78,31.09,0,91.86-64.07,91.86-64.07V454S619.47,494,591.75,494Zm-69.4,0c-4,0-7.4-1-7.4-6.43,0-9.32,45-77.44,79.38-77.44,6.1,0,9.71,3.26,9.71,7.47C604,433.54,532,494,522.35,494Z"
      transform="translate(-139 -312.09)"
      fill="currentColor"/>
    <path className="cls-1"
      d="M721.88,379.24c2.9,0,6.43,4,6.43,9.08,0,5.82-96.64,108.05-96.64,108.05L629,494c15.27-15.27,124.11-114.72,163.88-114.72C847.84,379.24,716,494,741.57,494s108.2-83.87,117.2-83.87c19.6,0-94.79,125.32-151.35,125.32-13.49,0-25.38-4.18-25.38-22.82,0-40.81,87.08-111.82,87.08-111.82l-.64,5.14c-36.31,0-154.24,129.5-162.91,129.5s-13.82-2.9-13.82-8.68C591.75,513.56,712.57,379.24,721.88,379.24Z"
      transform="translate(-139 -312.09)"
      fill="currentColor"/>
    <path className="cls-2"
      d="M686.26,606.62c-12-33.59-199.93-63.48-310.13-24.14-48.38,17.27-55.3,95.49-35.24,105,10.94,5.18,35.09-37.52,75.4-51.92C595,571.76,703.05,653.66,686.26,606.62Z"
      transform="translate(-139 -312.09)"
      fill="currentColor"/>
  </svg>
);

export default IconLoader;
